import tw from "twin.macro"

export const Wrapper = tw.div`
  max-w-xl mx-auto px-2-4 md:px-7-5 pt-6-4 md:pt-12-4 pb-6 md:pb-12
`

export const Divider = tw.hr`
  mt-5 mb-3 md:mb-8
`

export const Title = tw.h1`
  text-30 leading-none  md:text-60 md:leading-none md: font-bold uppercase text-center mb-3 md:mb-1-2
`
