import React from "react"
import { useMounted } from "../../../hooks/useMounted"

import { useWishlist, useWishlistContext } from "../../../hooks/useWishlist"

export const withWishlistCard = Component => ({ name = "WishlistCard" }) => {
  const { hasMounted } = useMounted()
  const { sharedWishlist, getSharedWishlist, moveToCart, deleteFromWishlist, loading } = useWishlist()

  const { wishlist } = useWishlistContext()

  const shared = hasMounted ? location?.search?.includes("id") : false
  if (shared && !sharedWishlist?.length) getSharedWishlist()

  const handleDelete = async id => await deleteFromWishlist(id)
  const handleMove = async (id, variantId) => await moveToCart(id, variantId)

  Component.displayName = name
  return <Component items={shared ? sharedWishlist : wishlist} handleDelete={handleDelete} handleMove={handleMove} loading={loading} />
}
