import React from "react"
import { graphql } from "gatsby"

import { Wishlist as Page } from "../components/Wishlist/Wishlist"

export const query = graphql`
  query {
    page: sanityPageAccountWishlist {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
