import React from "react"

import { withWishlist } from "./withWishlist"
import { Wrapper, Title, Divider } from "./WishlistStyles"
import { WishlistCard } from "./Card/WishlistCard"
import { WishlistShare } from "./Share/WishlistShare"

export const Wishlist = withWishlist(
  ({ title }): JSX.Element => (
    <Wrapper>
      <Title>{title}</Title>
      <WishlistShare />
      <Divider />
      <WishlistCard />
    </Wrapper>
  )
)
