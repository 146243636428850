import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useMounted } from "../../../hooks/useMounted"
import { useWishlist, useWishlistContext } from "../../../hooks/useWishlist"

export const withWishlistShare = Component => ({ name = "WishlistShare" }) => {
  const {
    config: {
      settings: {
        socialMedia: { sharing },
      },
    },
  } = useApp()

  const { wishlistShare } = useStaticQuery(graphql`
    query SANITY_PAGE_WISHLIST_SHARE {
      wishlistShare: sanityPageAccountWishlist {
        emailSharePopupTitle
        emailSharePopupDescription
        additionalEmailSharePopupSubmitButton
        additionalEmailSharePopupPlaceholder
        additionalSaveText
        additionalShareText
      }
    }
  `)

  const {
    emailSharePopupTitle,
    emailSharePopupDescription,
    additionalEmailSharePopupSubmitButton,
    additionalEmailSharePopupPlaceholder,
    additionalSaveText,
    additionalShareText,
  } = wishlistShare || {}

  const { shareWishlist, saveWishlist } = useWishlist()

  const { wishlist } = useWishlistContext()

  const { hasMounted } = useMounted()

  const [emailPopupActive, setEmailPopupActive] = useState(false)

  const [email, setEmail] = useState("")

  const shared = hasMounted ? location?.search?.includes("id") : false

  const handleShare = type => {
    const url = shareWishlist()
    switch (type) {
      case "email":
        window.open(`mailto:${email}?subject=Check my wishlist on Rossi boots&body=${url}`, "_blank")
        break
      case "facebook":
        window.open(`${sharing.facebook}${encodeURIComponent(url)}`, "facebook-popup", "height=400,width=600")
        break
      case "pinterest":
        window.open(`${sharing.pinterest}${url}&media=${wishlist?.[0]?.images?.[0].src}&description=Check my wishlist on Rossi boots`)
        break
      default:
        break
    }
  }

  const handleSave = () => saveWishlist()

  const handleChange = ({ target: { value } }) => {
    setEmail(value)
  }

  Component.displayName = name
  return (
    <Component
      shared={shared}
      count={wishlist?.length}
      handleShare={handleShare}
      handleSave={handleSave}
      email={email}
      handleChange={handleChange}
      emailPopupActive={emailPopupActive}
      setEmailPopupActive={setEmailPopupActive}
      additionalShareText={additionalShareText}
      additionalSaveText={additionalSaveText}
      emailSharePopupTitle={emailSharePopupTitle}
      emailSharePopupDescription={emailSharePopupDescription}
      additionalEmailSharePopupSubmitButton={additionalEmailSharePopupSubmitButton}
      additionalEmailSharePopupPlaceholder={additionalEmailSharePopupPlaceholder}
    />
  )
}
