import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`flex flex-row justify-center items-center`}
  > span {
    ${tw`mr-1-2`}
  }
`

export const EmailForm = styled.form`
  ${tw`py-4-8 px-3-6 w-full bg-white`}
  button {
    ${tw`mt-0-8`}
  }
`

export const Title = tw.h3`
  text-18 leading-none  mb-1-6 uppercase font-bold max-w-22
`

export const Description = tw.p`
  text-12 leading-none mb-2-4
`
