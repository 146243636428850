import React from "react"

import { useShopifyVariants } from "../../../hooks/useShopify"
import { useApp } from "../../../hooks/useApp"

export const withWishlistItem = Component => ({ name = "WishlistItem", item, handleDelete }) => {
  const { localActiveVariant: activeVariant, selectedOptions, handleVariant } = useShopifyVariants({
    product: item,
    preSelectedVariantTitle: item?.selectedTitle,
  })

  const {
    config: {
      settings: {
        constraints: { SIZE },
        routes: { PRODUCT },
      },
    },
  } = useApp()

  const { id, images, handle, title, options } = item

  const url = `${PRODUCT}/${handle}`

  Component.displayName = name
  return (
    <Component
      product={item}
      id={id}
      url={url}
      title={title}
      images={images}
      activeVariant={activeVariant}
      options={options}
      selectedOptions={selectedOptions}
      handleVariant={handleVariant}
      handleDelete={handleDelete}
      SIZE={SIZE}
    />
  )
}
