import React from "react"

import { withWishlistShare } from "./withWishlistShare"
import { Wrapper, EmailForm, Title, Description } from "./WishlistShareStyles"
import { Icon } from "../../Icon/Icon"
import { Popup } from "../../Popup/Popup"
import { StyledInput } from "../../Styled/Form"
import { StyledButton } from "../../Styled/Button"

export const WishlistShare = withWishlistShare(
  ({
    shared,
    count,
    handleShare,
    handleSave,
    email,
    handleChange,
    emailPopupActive,
    setEmailPopupActive,
    emailSharePopupTitle,
    emailSharePopupDescription,
    additionalEmailSharePopupSubmitButton,
    additionalEmailSharePopupPlaceholder,
    additionalSaveText,
    additionalShareText,
  }) => (
    <>
      <Wrapper>
        {!shared ? (
          <>
            <span>{additionalShareText}</span>
            <button disabled={count === 0} onClick={() => setEmailPopupActive(true)}>
              <Icon name={"email"} width={30} height={30} />
            </button>
            <button disabled={count === 0} onClick={() => handleShare("facebook")}>
              <Icon name={"facebook"} width={30} height={30} />
            </button>
            <button disabled={count === 0} onClick={() => handleShare("pinterest")}>
              <Icon name={"pinterest"} width={30} height={30} />
            </button>
          </>
        ) : (
          <button onClick={handleSave}>{additionalSaveText}</button>
        )}
      </Wrapper>
      <Popup active={emailPopupActive} setActive={setEmailPopupActive} width={"sm"}>
        <EmailForm onSubmit={() => handleShare("email")}>
          <Title>{emailSharePopupTitle}</Title>
          <Description>{emailSharePopupDescription}</Description>
          <StyledInput value={email} onChange={handleChange} spacing={"true"} placeholder={additionalEmailSharePopupPlaceholder} required />
          <StyledButton type={"submit"} wide colour={"black-reverse"}>
            {additionalEmailSharePopupSubmitButton}
          </StyledButton>
        </EmailForm>
      </Popup>
    </>
  )
)
