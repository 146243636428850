import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  grid grid-cols-1 md:grid-cols-3 gap-x-9-5 gap-y-8
`

export const EmptyWrapper = styled.div`
  ${tw`text-center pt-4-8 md:pt-3`}
  a {
    ${tw`px-2-8`}
  }
`

export const Title = tw.h3`text-18 leading-none mb-3-2 max-w-24-5 mx-auto md:max-w-auto`
